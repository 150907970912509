import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Snatch Balance 5×2`}</p>
    <p>{`OHS 5×2\\@85% 1RM`}</p>
    <p>{`OH Band Pull Aparts 5×8`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`25/20 Calorie Assault Bike`}</p>
    <p>{`10 Power Snatches (115/75) (RX+ 135/95)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      